import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: left;
  --yf-text--color: var(--yf-color--grey30);
  color: var(--yf-text--color);
`;

interface Props {
  bullets: (string)[];
}

export const linkStyle = {
  color: 'var(--yf-color--blue30)',
  fontFamily: 'var(--yf-font-combined--medium)',
  boxShadow: 'none'
};

export const Bullets = (props: Props) => {
  const { bullets } = props;

  return (
    <Wrapper role='presentation'>
      <ol className='yf-list yf-list--unordered text-loader--list text-loader--list_items'>
        {bullets.map((bullet, index) => {
          return (
            <li className='yf-list-item text-loader--list_element' key={index}>
              <span className='text-loader--content text-loader--list_element--text'>{bullet}</span>
            </li>
          );
        })}
      </ol>
    </Wrapper>
  );
};
