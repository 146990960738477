import { randomNumber } from '~utils/randomNumber';
import { replies } from '~utils/replies';

export const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

export let backendEndpoint = 'https://api.web.tbh.fun';
// export let backendEndpoint = 'http://localhost:8000';

if (process.env.NODE_ENV === 'development') {
  // backendEndpoint = 'https://dev.api.web.tbh.fun';
  // backendEndpoint = 'http://localhost:8000';
}

export const isProd = process.env.NODE_ENV === 'prod';

export const amplitudeKey = '9a35931ef48a9b97d2d99a5d65dfd8e2';

export const supportPhone = '1-310-210-7654';
export const supportEmail = 'support@tbh.fun';
export const website = 'https://tbh.fun/';
export const iosApp = 'https://apps.apple.com/us/app/id1536474130';
export const androidApp = 'https://play.google.com/store/apps/details?id=fun.tbh.app';

export const defaultReplies = replies;

export const numTapsInit = randomNumber(109, 200);
export const numTapsMax = 459;
