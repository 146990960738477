import React, { useEffect } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { getRedirectUrl } from '~utils/getRedirectUrl';

export const LandingPage = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    const host = location.host;
    if (host === 'cbhsapp.com') {
      logEvent('CBHS');
    } else if (host === 'carmelapp.net') {
      logEvent('school:carmel');
    } else if (host === 'unlock.school') {
      const pathParts = location?.pathname?.split('/');
      if (pathParts?.length > 1) {
        const school = pathParts[1];
        logEvent(`unlock.school:${school}`);
      }
    }
    window.location.assign(getRedirectUrl());
  }, [logEvent]);

  // const dlClick = useCallback(() => {
  //   window.location.assign('https://apps.apple.com/us/app/id1536474130');
  // }, []);

  return (
    <div className='container landing-container'>
      {/*<img className='logo' src={logo} alt='logo' />*/}
      {/*<img className='app-store-btn' src={appStoreDL} alt='app-store-btn' onClick={dlClick} />*/}
      {/*<div className='footer'>*/}
      {/*  <Link to={{ pathname: `/terms` }}>Terms</Link>*/}
      {/*  &middot;*/}
      {/*  <Link to={{ pathname: `/privacy` }}>Privacy</Link>*/}
      {/*  &middot;*/}
      {/*  <a href='mailto:support@tbh.fun'>Contact</a>*/}
      {/*</div>*/}
    </div>
  );
};
