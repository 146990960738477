import { useContext } from 'react';
import { StateContext } from '~contexts/main';
import { State } from '~models/interfaces';

export const useStateContext = (): State => {
  const state = useContext(StateContext);

  if (state === undefined) {
    throw new Error('Uh oh, where is my state?');
  }

  return state;
};
