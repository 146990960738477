import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import Routing from '~routing';

const firebaseConfig = {
  apiKey: 'AIzaSyCXDGBCmtk0KmuZeWNhF0sJoPZumr6177E',
  authDomain: 'tbh-app-68eda.firebaseapp.com',
  projectId: 'tbh-app-68eda',
  storageBucket: 'tbh-app-68eda.appspot.com',
  messagingSenderId: '53982404839',
  appId: '1:53982404839:web:ceaf6aefc3058af7df8242',
  measurementId: 'G-GZNS25B7E1'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => (
  <Router>
    <Routing />
  </Router>
);

render(<App />, document.getElementById('root'));
