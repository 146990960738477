export const replies = [
  {
    'id': 2,
    'text': 'who you tryna vote for in 2024 👀',
    'type': 'question',
    'active': true
  },
  {
    'id': 4,
    'text': 'do u use pronouns',
    'type': 'question',
    'active': true
  },
  {
    'id': 5,
    'text': 'whats the last thing you ate?',
    'type': 'question',
    'active': true
  },
  {
    'id': 6,
    'text': 'where are you from...?',
    'type': 'question',
    'active': true
  },
  {
    'id': 8,
    'text': 'who do you miss the most',
    'type': 'question',
    'active': true
  },
  {
    'id': 9,
    'text': 'whats ur fav food',
    'type': 'question',
    'active': true
  },
  {
    'id': 10,
    'text': 'Biggest red flags for a girl?',
    'type': 'question',
    'active': true
  },
  {
    'id': 11,
    'text': 'when was the last time you lied?',
    'type': 'question',
    'active': true
  },
  {
    'id': 12,
    'text': 'what was your favorite show when you were a kid?',
    'type': 'question',
    'active': true
  },
  {
    'id': 14,
    'text': 'is this the best app tho :)',
    'type': 'question',
    'active': true
  },
  {
    'id': 15,
    'text': 'who was your crush in middle school lmao',
    'type': 'question',
    'active': true
  },
  {
    'id': 16,
    'text': 'Biggest red flags for a girl?',
    'type': 'question',
    'active': true
  },
  {
    'id': 17,
    'text': 'kanye is the best 😤 change my mind',
    'type': 'question',
    'active': true
  },
  {
    'id': 18,
    'text': 'do you have any piercings?',
    'type': 'question',
    'active': true
  },
  {
    'id': 20,
    'text': 'craziest pickup line that worked?',
    'type': 'question',
    'active': true
  },
  {
    'id': 21,
    'text': 'who are your best girl friends',
    'type': 'question',
    'active': true
  },
  {
    'id': 22,
    'text': 'when was the last time you cried?',
    'type': 'question',
    'active': true
  },
  {
    'id': 23,
    'text': 'The weirdest trend you\'ve done',
    'type': 'question',
    'active': true
  },
  {
    'id': 24,
    'text': 'how many exes do you have?',
    'type': 'question',
    'active': true
  },
  {
    'id': 25,
    'text': 'did you watch game of thrones?',
    'type': 'question',
    'active': true
  },
  {
    'id': 26,
    'text': 'have you ever watched harry potter?',
    'type': 'question',
    'active': true
  },
  {
    'id': 27,
    'text': 'name all your exes',
    'type': 'question',
    'active': true
  },
  {
    'id': 28,
    'text': 'whos ur crush?',
    'type': 'question',
    'active': true
  },
  {
    'id': 29,
    'text': 'wyd tomorrow',
    'type': 'question',
    'active': true
  },
  {
    'id': 30,
    'text': 'your worst personality characteristic?',
    'type': 'question',
    'active': true
  },
  {
    'id': 31,
    'text': 'any pets?',
    'type': 'question',
    'active': true
  },
  {
    'id': 32,
    'text': 'Biggest red flags for a girl?',
    'type': 'question',
    'active': true
  },
  {
    'id': 33,
    'text': 'have u seen breaking bad',
    'type': 'question',
    'active': true
  },
  {
    'id': 34,
    'text': 'the worst pick up line you\'ve ever heard?',
    'type': 'question',
    'active': true
  },
  {
    'id': 35,
    'text': 'where are you from...?',
    'type': 'question',
    'active': true
  },
  {
    'id': 36,
    'text': 'how are you feeling today :)',
    'type': 'question',
    'active': true
  },
  {
    'id': 37,
    'text': 'would you ever be fwb',
    'type': 'question',
    'active': true
  },
  {
    'id': 38,
    'text': 'I know what you did',
    'type': 'question',
    'active': true
  },
  {
    'id': 39,
    'text': 'how many selfies do you take a day?',
    'type': 'question',
    'active': true
  },
  {
    'id': 40,
    'text': 'whats your sign',
    'type': 'question',
    'active': true
  },
  {
    'id': 41,
    'text': 'spill some tea',
    'type': 'question',
    'active': true
  },
  {
    'id': 42,
    'text': 'what time do you usually go to bed?',
    'type': 'question',
    'active': true
  },
  {
    'id': 47,
    'text': 'introvert or extrovert?',
    'type': 'question',
    'active': true
  },
  {
    'id': 48,
    'text': 'ever gone skinny dipping?',
    'type': 'question',
    'active': true
  },
  {
    'id': 49,
    'text': 'The weirdest trend you\'ve done',
    'type': 'question',
    'active': true
  },
  {
    'id': 50,
    'text': 'your favorite part of your body?',
    'type': 'question',
    'active': true
  },
  {
    'id': 51,
    'text': 'uh what did you eat for breakfast?',
    'type': 'question',
    'active': true
  },
  {
    'id': 52,
    'text': 'do you have a middle name?',
    'type': 'question',
    'active': true
  },
  {
    'id': 53,
    'text': 'you wild for what you did',
    'type': 'question',
    'active': true
  },
  {
    'id': 54,
    'text': 'are you straight?',
    'type': 'question',
    'active': true
  },
  {
    'id': 55,
    'text': 'have you ever cheated, you gotta be honest lol',
    'type': 'question',
    'active': true
  },
  {
    'id': 56,
    'text': 'when was the last time you lied?',
    'type': 'question',
    'active': true
  },
  {
    'id': 57,
    'text': 'how big of nerd are you 1 to 10',
    'type': 'question',
    'active': true
  },
  {
    'id': 59,
    'text': 'the worst trouble you got into as a kid?',
    'type': 'question',
    'active': true
  },
  {
    'id': 60,
    'text': 'do you snore?',
    'type': 'question',
    'active': true
  },
  {
    'id': 61,
    'text': 'whats ur fav tv show',
    'type': 'question',
    'active': true
  },
  {
    'id': 62,
    'text': 'biggest regret?',
    'type': 'question',
    'active': true
  },
  {
    'id': 63,
    'text': 'how tall are you??',
    'type': 'question',
    'active': true
  },
  {
    'id': 64,
    'text': 'you should get a tattoo',
    'type': 'question',
    'active': true
  },
  {
    'id': 65,
    'text': 'please give me pickup lines that always work',
    'type': 'question',
    'active': true
  },
  {
    'id': 66,
    'text': 'whos ur worst enemy 😈',
    'type': 'question',
    'active': true
  },
  {
    'id': 68,
    'text': 'what did you dream about last night?',
    'type': 'question',
    'active': true
  },
  {
    'id': 69,
    'text': 'are you judgemental',
    'type': 'question',
    'active': true
  },
  {
    'id': 71,
    'text': 'What\'s ur fav brand?? <3',
    'type': 'question',
    'active': true
  },
  {
    'id': 72,
    'text': 'when was the last time you wet the bed?',
    'type': 'question',
    'active': true
  },
  {
    'id': 73,
    'text': 'personality or looks?',
    'type': 'question',
    'active': true
  },
  {
    'id': 75,
    'text': 'tell me a random fact',
    'type': 'question',
    'active': true
  },
  {
    'id': 80,
    'text': 'why u ain’t follow me back',
    'type': 'question',
    'active': true
  },
  {
    'id': 81,
    'text': 'would you ever get with me? -J',
    'type': 'question',
    'active': true
  }
];