import React, { useCallback } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ad from '~assets/img/ad-black.png';
import sent from '~assets/img/tick.png';
import { iosApp } from '~constants';
import { useContext } from '~hooks/useContext/useContext';
import { useNumTaps } from '~hooks/useNumTaps';
import { browserReport } from '~utils/browserReport';
import { getVid } from '~utils/cookie';

export const SentPage = () => {
  const [state, dispatch] = useContext();
  const { logEvent } = useAmplitude();
  const navigate = useNavigate();
  const params = useParams();
  const [numTaps] = useNumTaps();

  const backHandler = useCallback((ev) => {
    if (params.link) {
      const report = browserReport();
      dispatch?.getLinkUpdateState({
        vid: getVid(),
        link: params.link,
        promptId: params.promptId,
        device: `${report.os.name}_${report.os.version}_${report.os.model}_${report.lang}`
      });
    }
    logEvent('web:sent:send-another');
    navigate(-1);
  }, [navigate, params.link, dispatch?.getLinkUpdateState]);

  const adHandler = useCallback(() => {
    logEvent('web:ad-click', [{ site: 'yofame', page: 'sent', format: 'ad-black' }]);
    window.open('https://yofame.com/?ref=tbh_sent', '_blank');
  }, []);

  return (
    <div className='container sent-container'>
      <LogOnMount eventType='page:sent' />
      <img className='ad pulse-minor' src={ad} alt='get-followers' onClick={adHandler} />
      <img className='check' src={sent} alt='sent' />
      <h1 className='sent'>Sent!</h1>
      <h2 className='download-prompt'>👇 {numTaps} people just tapped the button👇 </h2>
      <a className='button pulse' href={iosApp} target='_blank'>
        Get your own messages!
      </a>
      <a className='another' onClick={backHandler}>Send another message</a>
    </div>
  );
};
