import { useEffect, useState } from 'react';
import { numTapsInit, numTapsMax } from '~constants';
import { getCookie, setCookie } from '~utils/cookie';
import { randomNumber } from '~utils/randomNumber';

export function useNumTaps() {
  const [numTaps, setNumTaps] = useState(getNumTapsCookie());

  useEffect(() => {
    const interval = setInterval(() => {
      if (numTaps < numTapsMax) {
        const shouldAdd = randomNumber(0, 4) !== 0;
        const rand = randomNumber(0, shouldAdd ? 4 : 3);
        const newTaps = shouldAdd ? numTaps + rand : numTaps - rand;
        setNumTaps(newTaps);
        setCookie('taps', newTaps.toString());
      }
    }, 1200);
    return () => clearInterval(interval);
  }, [numTaps]);

  return [
    numTaps,
    setNumTaps
  ];
}

function getNumTapsCookie(): number {
  if (!getCookie('taps')) {
    setCookie('taps', numTapsInit.toString());
  }
  return parseInt(getCookie('taps') ?? numTapsInit.toString());
}