import React, { useCallback, useMemo, useState } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from '~hooks/useContext/useContext';
import { useNumTaps } from '~hooks/useNumTaps';
import { ReplyItem } from '~models/api/ReplyItem';
import { browserReport } from '~utils/browserReport';
import { getVid } from '~utils/cookie';
import { getRedirectUrl } from '~utils/getRedirectUrl';
import { randomNumber } from '~utils/randomNumber';

export const MainPage = () => {
  const [state, dispatch] = useContext();
  const params = useParams();
  const navigate = useNavigate();
  const { logEvent } = useAmplitude();

  if (!state.replies) return <div />;

  const [reply, setReply] = useState(null as unknown as ReplyItem);
  const [text, setText] = useState('');
  const [numTaps] = useNumTaps();

  const rollDice = useCallback(() => {
    if (!state.replies) return;
    const reply = state.replies[randomNumber(0, state.replies.length)];
    setReply(reply);
    setText(reply.text);
  }, [state.replies]);

  const onChangeHandler = useCallback((ev) => {
    setReply(null as unknown as ReplyItem);
    setText(ev.target.value);
  }, []);

  // const adHandler = useCallback(() => {
  //   logEvent('web:ad-click', [{ site: 'yofame', page: 'main', format: 'ad-white' }]);
  //   window.open('https://yofame.com/?ref=tbh_main', '_blank');
  // }, []);

  const submitHandler = useCallback((ev) => {
    ev.preventDefault();
    if (!params.link || !state.userPromptId || !state.userPromptVoteId) return;
    const report = browserReport();
    const success = dispatch?.postReply({
      vid: getVid(),
      link: params.link,
      text,
      device: `${report.os.name}_${report.os.version}_${report.os.model}_${report.lang}`,
      replyId: reply?.id ?? null,
      userPromptId: state.userPromptId,
      userPromptVoteId: state.userPromptVoteId
    });
    if (success) {
      if (params.promptId) {
        navigate(`/${params.link}/${params.promptId}/sent`);
      } else {
        navigate(`/${params.link}/sent`);
      }
    }
  }, [text, reply, params.link, state.userPromptId, state.userPromptVoteId]);

  const redirectUrl = useMemo(() => getRedirectUrl(), []);

  return (
    <>
      <div className='container'>
        <LogOnMount eventType='page:home' />

        {/*<img className='ad pulse-minor' src={ad} alt='get-followers' onClick={adHandler} />*/}

        <form className='form' method='post'>
          <div className='bubble'>
            <div className='header'>
              <div className='user-container'>
                <div className='username'>@{state.username}</div>
                <div className='prompt'>{state.prompt?.text}</div>
              </div>
            </div>
            <div className='textarea-container'>
              <div className='top-right-corner' />
              <div className='top-left-corner' />
              <div className='dice-button' onClick={rollDice}>🎲</div>
              <textarea
                placeholder={`${state.prompt?.text}`}
                value={text ?? ''}
                onChange={onChangeHandler}
                name='question'
                autoComplete='off'
              />
            </div>
          </div>
          <div className='anonymous-tooltip'>🔒 anonymous q&a</div>
          <button
            className='submit'
            type='submit'
            onClick={submitHandler}
            style={{ display: text ? '' : 'none' }}
          >
            Send!
          </button>
        </form>
      </div>

      <div className='bottom-container'>
        <h2 className='download-prompt'>👇 {numTaps} people just tapped the button👇 </h2>
        <a
          className='button download-link pulse'
          href={redirectUrl}
          target='_blank'
        >
          Get your own messages!
        </a>
      </div>
    </>
  );
};
