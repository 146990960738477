import { useContext } from 'react';
import { DispatchContext } from '~contexts/main';
import { Dispatch } from '~models/interfaces';

export const useDispatchContext = (): Dispatch => {
  const dispatch = useContext(DispatchContext);

  if (dispatch === undefined) {
    throw new Error('Uh oh, where is my dispatch?');
  }

  return dispatch;
};
