import React, { useEffect } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { useParams } from 'react-router-dom';
import { useContext } from '~hooks/useContext/useContext';
import { getVid } from '~utils/cookie';
import { browserReport } from '~utils/browserReport';

export const Loading = () => {
  const [state, dispatch] = useContext();
  const params = useParams();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    if (state.loading && params.link) {
      const report = browserReport();
      dispatch?.getLinkUpdateState({
        vid: getVid(),
        link: params.link,
        promptId: params.promptId,
        device: `${report.os.name}_${report.os.version}_${report.os.model}_${report.lang}`
      });
      logEvent('loading:getLink', { promptId: params.promptId });
    }
  }, [state.loading, params.link, dispatch?.getLinkUpdateState]);

  return (
    <>
      <LogOnMount eventType='loading' />
      <div className='loading' />
    </>
  );
};
