import { androidApp, iosApp } from '~constants';
import { browserReport } from '~utils/browserReport';

export function getRedirectUrl(): string {
  const report = browserReport();
  const isIOS = report.os.name === 'iOS';
  const isAndroid = report.os.name === 'Android';

  let redirectUrl = isAndroid ? androidApp : iosApp;

  const host = location?.host;
  if (host === 'unlock.school') {
    const pathParts = location?.pathname?.split('/');
    if (pathParts?.length > 1) {
      const school = pathParts[1];
      if (school === 'WHS' || school === 'whs') {
        if (isIOS) {
          redirectUrl = 'https://apps.apple.com/us/app/tbh/id1536474130?ppid=67e8aebc-f6e6-4605-8ae2-034a49d8c151';
        } else if (isAndroid) {
          // redirectUrl = '';
        }
      }
    }
  } else if (host === 'cbhsapp.com') {
    if (isIOS) {
      redirectUrl = 'https://apps.apple.com/us/app/tbh/id1536474130?ppid=390210d4-e6be-460b-bb9a-eff38f2d4f0b';
    } else if (isAndroid) {
      redirectUrl = 'https://play.google.com/store/apps/details?id=fun.tbh.app&listing=cbhs';
    }
  } else if (host === 'carmelapp.net') {
    if (isIOS) {
      redirectUrl = 'https://apps.apple.com/us/app/tbh/id1536474130?ppid=2952fbba-1b7d-446a-b758-26ae66531342';
    } else if (isAndroid) {
      redirectUrl = 'https://play.google.com/store/apps/details?id=fun.tbh.app&listing=carmel_hs';
    }
  }

  return redirectUrl;
}