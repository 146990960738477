import React from 'react';
import { LogOnMount } from 'react-amplitude-hooks';
import { NavLink } from 'react-router-dom';
import { linkStyle } from '~features/Bullets';
import { Content, InnerWrapper, OuterWrapper, Title } from '~features/Typography';

type Props = {
  errorCode: string;
};

export const PageError = (props: Props) => {
  return (
    <OuterWrapper>
      <InnerWrapper style={{ minHeight: window.innerHeight }}>
        <LogOnMount eventType="page:error" />

        <Content style={{ paddingBottom: 20 }}>
          <Title style={{ fontSize: 22, paddingBottom: 30, paddingTop: 10 }}>
            Ooops... looks like an error occurred! ({props.errorCode})
          </Title>

          <NavLink style={{ ...linkStyle, textAlign: 'center', fontSize: 20 }} to={`/`} className="white">
            Go Back Home <span className="logo-single" />
          </NavLink>
        </Content>
      </InnerWrapper>
    </OuterWrapper>
  );
};
