import amplitude from 'amplitude-js';
import React from 'react';
import { Amplitude, AmplitudeProvider } from 'react-amplitude-hooks';
import { Route, Routes } from 'react-router-dom';
import '~assets/styles/index.scss';
import { amplitudeKey, isProd } from '~constants';
import { Provider } from '~contexts';
import { Loading } from '~features/Loading';
import { useContext } from '~hooks/useContext/useContext';
import { PageError } from '~pages/error/PageError';
import { PagePrivacy, PageTerms } from '~pages/legal';
import { MainPage } from '~pages/main';
import { LandingPage } from '~pages/main/LandingPage';
import { SentPage } from '~pages/main/SentPage';
import { getVid } from '~utils/cookie';

export default () => {
  return (
    <Provider>
      <AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={amplitudeKey} userId={getVid()}>
        <Amplitude
          eventProperties={{
            isProd
          }}
        >
          <Routing />
        </Amplitude>
      </AmplitudeProvider>
    </Provider>
  );
};

const Routing = () => {
  const [state] = useContext();

  return (
    <Routes>
      {location.host === 'unlock.school' && <Route path={`/WHS`} element={<LandingPage />} />}
      {location.host === 'unlock.school' && <Route path={`/whs`} element={<LandingPage />} />}
      <Route path={`/terms`} element={<PageTerms />} />
      <Route path={`/privacy`} element={<PagePrivacy />} />
      <Route path={`/error`} element={<PageError errorCode={'404'} />} />
      <Route path={`/download`} element={<LandingPage />} />
      <Route path={`/:link/:promptId`} element={state.loading ? <Loading /> : <MainPage />} />
      <Route path={`/:link/:promptId/sent`} element={state.loading ? <Loading /> : <SentPage />} />
      <Route path={`/:link`} element={state.loading ? <Loading /> : <MainPage />} />
      <Route path={`/:link/sent`} element={state.loading ? <Loading /> : <SentPage />} />
      <Route path={`/`} element={<LandingPage />} />
      <Route path='*' element={<PageError errorCode={'404'} />} />
    </Routes>
  );
};
