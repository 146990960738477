import React, { createContext, useMemo } from 'react';
import { getLink } from '~api/getLink';
import { postReply } from '~api/postReply';
import { defaultReplies } from '~constants';
import { useImmer } from '~hooks/useImmer';
import { GetLinkResponse } from '~models/api/GetLinkResponse';
import { Dispatch } from '~models/Dispatch';
import { State } from '~models/State';

export const StateContext = createContext<State>(initializeState());
export const DispatchContext = createContext<Dispatch>(null);

export const Provider = ({ children }) => {
  const defaultState = useMemo(() => initializeState(), []);

  const [state, updateState] = useImmer({ ...defaultState });

  const defaultDispatch = useMemo(() => initializeDispatch(updateState), [updateState]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={defaultDispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

function initializeState(): State {
  return {
    loading: true
  };
}

function initializeDispatch(updateState: any): Dispatch {
  const getLinkUpdateState = (async ({ vid, link, promptId, device }) => {
    const result: GetLinkResponse = await getLink({ vid, link, promptId, device });
    const { avatar, username, prompt, replies, userPromptId, userPromptVoteId } = result;

    updateState((draft) => {
      draft.loading = false;
      draft.avatar = avatar;
      draft.username = username;
      draft.prompt = prompt;
      draft.replies = replies ? replies : defaultReplies;
      draft.userPromptId = userPromptId;
      draft.userPromptVoteId = userPromptVoteId;
    });
  });

  return {
    getLinkUpdateState,
    postReply
  };
}
