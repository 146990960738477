import styled from 'styled-components';

export const OuterWrapper = styled.div`
  background: #1f1f1f;
`;

export const InnerWrapper = styled.div`
  max-width: 700px;
  margin: auto;
  min-height: ${(props) => props.minHeight ?? `${window.innerHeight}px`};
  background: ${(props) => props.background ?? props.theme.background};
`;

export const Content = styled.section`
  font-family: var(--yf-base-font_family, system);
  -webkit-font-smoothing: antialiased;

  max-width: 650px;
  margin: auto;

  background: ${(props) => props.background ?? props.theme.background};
  color: ${(props) => props.theme.textColor};
  text-align: center;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  padding: 0 var(--yf-size--3x);
  padding-bottom: 70px;
`;

export const Paragraph = styled.p`
  padding: 10px 0;
`;

export const Title = styled.h1`
  letter-spacing: -0.7px;
  font-size: var(--yf-font-size--70, 28px);
  line-height: var(--yf-line-height--70, 36px);
  font-family: var(--yf-font-combined--medium);
  font-weight: var(--yf-heading--font_weight);
  color: ${(props) => props.textColor ?? props.theme.textColor};
  transition: font 0.33s ease, color 0.33s ease, opacity 0.33s ease;
`;

export const SubTitle = styled.h2`
  font-size: var(--yf-font-size--30, 14px);
  -webkit-padding-before: var(--yf-size--4x);
  padding-block-start: var(--yf-size--4x);
  line-height: var(--yf-line-height--30);
  transition: font 0.33s ease, color 0.33s ease, opacity 0.33s ease;
  display: block;
  outline: none;
  letter-spacing: 0;
  text-align: ${(props) => (props.theme.flow === 'a' ? 'center' : 'center')};
  font-weight: 500;
  font-family: var(--yf-font-combined--medium);
  --yf-text--color: ${(props) => props.textColor ?? props.theme.textColor};
  color: ${(props) => props.textColor ?? props.theme.textColor};
  padding: 10px 0;
  margin-top: 2px;
`;

export const ErrorText = styled.p`
  font-family: var(--yf-font-combined--medium);
  line-height: var(--yf-line-height--unitless);
  margin: 0 var(--yf-form-item-horizontal_spacer) 0;
  padding-top: ${(props) => (props.theme.flow === 'b' ? '20px' : 'var(--yf-size--half)')};
  max-width: none;
  font-size: ${(props) => (props.theme.flow === 'b' ? '15px' : 'var(--yf-font-size--20)')};
  font-weight: ${(props) => (props.theme.flow === 'b' ? 600 : 500)};
  text-align: ${(props) => (props.theme.flow === 'b' ? 'left' : 'right')};
  color: var(--yf-color--red20);
`;

export const FormItemLabel = styled.label`
  display: block;
  text-align: left;
  margin: ${(props) =>
  props.theme.flow === 'b' ? '20px 0 20px 10px' : '0 var(--yf-form-item-horizontal_spacer) var(--yf-size)'};
  color: ${(props) => (props.theme.flow === 'b' ? 'black' : 'var(--yf-color--grey30)')};
  font-size: ${(props) => (props.theme.flow === 'b' ? '17px' : 'var(--yf-font-size--30)')};
  font-weight: ${(props) => (props.theme.flow === 'b' ? 600 : 500)};
  font-family: var(--yf-font-combined--medium);
  line-height: var(--yf-line-height--unitless);
`;
