import produce, { Draft } from 'immer';
import { useCallback, useState } from 'react';

export function useImmer<S = any>(initialValue: S | (() => S)): [S, (f: (draft: Draft<S>) => void | S) => void];

export function useImmer(initialValue: any) {
  const [val, updateValue] = useState(initialValue);
  return [
    val,
    useCallback(
      (updater) => {
        const nextVal = produce(val, updater);
        updateValue(nextVal);
        return nextVal;
      },
      [val]
    )
  ];
}
