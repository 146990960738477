import axios, { AxiosResponse } from 'axios';
import { backendEndpoint } from '~constants';
import { GetLinkResponse } from '~models/api/GetLinkResponse';
import { GetLinkRequest } from '~models/api/GetLinkRequest';

export async function getLink({ vid, link, promptId, device }: GetLinkRequest): Promise<GetLinkResponse> {
  try {
    const result: AxiosResponse<GetLinkResponse> = await axios.post(`${backendEndpoint}/getLink`, {
      vid,
      link,
      promptId,
      device
    });

    return result.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}
