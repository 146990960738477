import React from "react";

export const PageTerms = () => {
  return (
    <div className="page-legal">
      <h2>
        Terms of Service
      </h2>
      <p>
        By downloading and using TBH (collectively "the app"), you are agreeing to all of these terms.
      </p>

      <h5>
        Age requirements
      </h5>
      <p>
        You must be 13 years or older to download and use the app.
      </p>

      <h5>
        Rights
      </h5>
      <p>
        We grant you the opportunity to use the app as a service. In no way are you allowed to exploit the service,
        attempt to hack the service, access unauthorized data, or use the app for commercial purposes, unless authorized
        in writing from the app. You may not reverse engineer or decompile the application or create any unauthorized
        service that ties into the app without permission.
      </p>

      <h5>
        Abuse
      </h5>
      <p>
        You agree to respect others, not commit any harassing or bullying/hateful behavior, and to not post anything
        offensive (including items that are suggestive, sexual, or related to drugs, offensive language, or illegal
        behavior). You agree not to post anything that violates copyrights.
      </p>

      <h5>
        Mobile Services and Safety
      </h5>
      <p>
        The app is a mobile application and therefore uses messaging, data, and device processing. You are responsible
        for all costs associated with these services. You are also responsible for the safe handling of the application and
        use (or non-use) of it (e.g. while driving, etc).
      </p>

      <h5>
        No warranty
      </h5>
      <p>
        No warranty whatsoever, expressed or implied, is granted by the app. Use entirely for your own benefit and at
        your own risk. All content posted or actions of others are their sole responsibility. To the maximum extent permitted
        by law, you cannot hold the app, their employees, shareholders, affiliates or others related to the app liable
        for any damages or lost data.
      </p>

    </div>
  );
};
