interface BrowserReport {
  os: {
    name: string | null;
    version: string | null;
    model: string | null;
  };
  lang: readonly string[] | string | null;
}

export function browserReport(): BrowserReport {
  let report: BrowserReport;
  let match;

  const userAgent = navigator.userAgent;

  // initialize object to store results
  report = {
    os: {
      name: null,
      version: null,
      model: null
    },
    lang: null
  };

  // extract operating system name from user agent
  if (userAgent.indexOf('Windows') >= 0) {
    if (userAgent.indexOf('Windows Phone') >= 0) {
      report.os.name = 'Windows Phone';
    } else {
      report.os.name = 'Windows';
    }
  }

  if (userAgent.indexOf('OS X') >= 0 && userAgent.indexOf('Android') === -1) {
    report.os.name = 'OS X';
  }

  if (userAgent.indexOf('Linux') >= 0) {
    report.os.name = 'Linux';
  }

  if (userAgent.indexOf('like Mac OS X') >= 0) {
    report.os.name = 'iOS';
  }

  if ((userAgent.indexOf('Android') >= 0 || userAgent.indexOf('Adr') >= 0) && userAgent.indexOf('Windows Phone') === -1) {
    report.os.name = 'Android';
  }

  if (userAgent.indexOf('BB10') >= 0) {
    report.os.name = 'BlackBerry';
  }

  if (userAgent.indexOf('RIM Tablet OS') >= 0) {
    report.os.name = 'BlackBerry Tablet OS';
  }

  if (userAgent.indexOf('BlackBerry') >= 0) {
    report.os.name = 'BlackBerryOS';
  }

  if (userAgent.indexOf('CrOS') >= 0) {
    report.os.name = 'Chrome OS';
  }

  if (userAgent.indexOf('KAIOS') >= 0) {
    report.os.name = 'KaiOS';
  }

  // extract operating system version from user agent
  match = null;

  switch (report.os.name) {
    case 'Windows':
    case 'Windows Phone':
      if (userAgent.indexOf('Win16') >= 0) {
        report.os.version = '3.1.1';
      } else if (userAgent.indexOf('Windows CE') >= 0) {
        report.os.version = 'CE';
      } else if (userAgent.indexOf('Windows 95') >= 0) {
        report.os.version = '95';
      } else if (userAgent.indexOf('Windows 98') >= 0) {
        if (userAgent.indexOf('Windows 98; Win 9x 4.90') >= 0) {
          report.os.version = 'Millennium Edition';
        } else {
          report.os.version = '98';
        }
      } else {
        match = userAgent.match(/Win(?:dows)?(?: Phone)?[ _]?(?:(?:NT|9x) )?((?:(\d+\.)*\d+)|XP|ME|CE)\b/);

        if (match && match[1]) {
          switch (match[1]) {
            case '6.4':
              // some versions of Firefox mistakenly used 6.4
              match[1] = '10.0';
              break;
            case '6.3':
              match[1] = '8.1';
              break;
            case '6.2':
              match[1] = '8';
              break;
            case '6.1':
              match[1] = '7';
              break;
            case '6.0':
              match[1] = 'Vista';
              break;
            case '5.2':
              match[1] = 'Server 2003';
              break;
            case '5.1':
              match[1] = 'XP';
              break;
            case '5.01':
              match[1] = '2000 SP1';
              break;
            case '5.0':
              match[1] = '2000';
              break;
            case '4.0':
              match[1] = '4.0';
              break;
            default:
              // nothing
              break;
          }
        }
      }
      break;
    case 'OS X':
      match = userAgent.match(/OS X ((\d+[._])+\d+)\b/);
      break;
    case 'Linux':
      // linux user agent strings do not usually include the version
      report.os.version = null;
      break;
    case 'iOS':
      match = userAgent.match(/OS ((\d+[._])+\d+) like Mac OS X/);
      break;
    case 'Android':
      match = userAgent.match(/(?:Android|Adr) (\d+([._]\d+)*)/);
      break;
    default:
      // no good default behavior
      report.os.version = null;
      break;
  }

  if (match && match[1]) {

    // replace underscores in version number with periods
    match[1] = match[1].replace(/_/g, '.');
    report.os.version = match[1];
  }

  // handle Mac OS X / OS X / macOS naming conventions
  if (report.os.name === 'OS X' && report.os.version) {

    let versions = report.os.version.split('.');
    if (versions.length >= 2) {
      let minorVersion = parseInt(versions[1], 10);
      if (minorVersion <= 7) {
        report.os.name = 'Mac OS X';
      } else if (minorVersion >= 12) {
        report.os.name = 'macOS';
      } else {
        report.os.name = 'OS X';
      }
    }

  }

  if (report.os.name === 'iOS') {
    if (navigator.userAgent.match(/iPhone/i)) {
      report.os.model = 'iPhone';
    }

    if (navigator.userAgent.match(/iPad/i)) {
      report.os.model = 'iPad';
    }

    if (navigator.userAgent.match(/iPod/i)) {
      report.os.model = 'iPod';
    }
  }

  // preferred language(s) for displaying pages
  report.lang = navigator.languages || navigator.language;

  return report;
}
