import axios, { AxiosResponse } from 'axios';
import { backendEndpoint } from '~constants';
import { PostReplyResponse } from '~models/api/PostReplyResponse';
import { PostReplyRequest } from '~models/api/PostReplyRequest';

export async function postReply(
  {
    vid,
    link,
    text,
    device,
    replyId,
    userPromptId,
    userPromptVoteId
  }: PostReplyRequest
): Promise<PostReplyResponse> {
  try {
    const result: AxiosResponse<PostReplyResponse> = await axios.post(`${backendEndpoint}/postReply`, {
      vid,
      link,
      text,
      device,
      replyId,
      userPromptId,
      userPromptVoteId
    });

    return {
      success: result?.data?.success
    };
  } catch (e) {
    console.error(e);
    return e;
  }
}
